/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Athènes"), React.createElement("p", null, "Pour se loger, Athènes s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "La ", React.createElement("a", {
    href: "https://www.booking.com/district/gr/athens/syntagma.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "place Syntagma"), " est le centre de la capitale grecque et le point de départ des balades vers le quartier touristique de ", React.createElement("a", {
    href: "https://www.booking.com/district/gr/athens/plaka.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Pláka"), ", un dédale de rues et de restaurants au pied de l’Acropole."), "\n", React.createElement(_components.li, null, "Au nord-ouest, le quartier animé de ", React.createElement("a", {
    href: "https://www.booking.com/district/gr/athens/psiri.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Psyrí"), " débouche sur la place Omonia, épicentre commerçant de la ville avec ses bâtisses néoclassiques."), "\n", React.createElement(_components.li, null, "A l’ouest, la ", React.createElement("a", {
    href: "https://www.booking.com/district/gr/athens/monastiraki.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "place Monastiraki"), ", plus bohème, est surtout connue pour ses puces."), "\n", React.createElement(_components.li, null, "Au nord-est, ", React.createElement("a", {
    href: "https://www.booking.com/district/gr/athens/kolonaki.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kolonaki"), ", au pied du mont Lycabette, est le quartier chic où sont nichées toutes les boutiques de luxe et les ambassades."), "\n", React.createElement(_components.li, null, "Au nord, ", React.createElement("a", {
    href: "https://www.booking.com/district/gr/athens/exarcheia.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Exarhia"), " enfin, quartier à la réputation anarchiste, est investi par les jeunes et les artistes."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
